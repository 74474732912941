










































































@import '@design';

.bubs-page-section {
  color: $color-bubs-charcoal;
  background-color: $color-bubs-wax-paper;
}
